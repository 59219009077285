export default {
  'acting.代理': 'Agen',
  'acting.详情': 'Detail',
  'acting.奖励': 'Hadiah',
  'acting.佣金': 'Komisi',
  'acting.规则': 'Aturan',

  'acting.generalboard.邀请朋友来获取': 'Undang teman untuk mendapatkan',
  'acting.generalboard.人': 'orang',
  'acting.generalboard.邀请奖励': 'Hadiah Undangan',
  'acting.generalboard.高达': 'Hingga',
  'acting.generalboard.佣金奖励': 'Hadiah Komisi',
  'acting.generalboard.描述': 'Dapatkan {amount} untuk setiap teman yang Anda undang, plus komisi hingga 90% dari taruhan mereka. Nikmati komisi yang konsisten, apakah mereka menang atau kalah. Mulai hasilkan sekarang!',
  'acting.generalboard.邀请链接': 'Tautan Undangan',
  'acting.generalboard.分享到社交平台': 'Bagikan ke platform sosial',
  'acting.generalboard.拉新表现': 'Rincian Undangan (Pendaftaran Sukses)',
  'acting.generalboard.今日拉新': 'Pendaftaran Hari Ini',
  'acting.generalboard.本周拉新': 'Pendaftaran Minggu Ini',
  'acting.generalboard.直属拉新表现': 'Pendaftaran Undangan Langsung',
  'acting.generalboard.非直属拉新表现': 'Pendaftaran Undangan Tidak Langsung',
  'acting.generalboard.下级详情': 'Rincian Bawahan (Ikatan Sukses)',
  'acting.generalboard.下级总人数': 'Jumlah Total Bawahan',
  'acting.generalboard.直属下级人数': 'Jumlah Bawahan Langsung',
  'acting.generalboard.非直属下级人数': 'Jumlah Bawahan Tidak Langsung',
  'acting.generalboard.总奖励': 'Total Hadiah',
  'acting.generalboard.总佣金': 'Total Komisi',
  'acting.generalboard.tip.描述': 'Jumlah di atas mewakili jumlah orang yang berhasil mendaftar game melalui pengundang.Untuk berhasil mengikat bawahan, orang yang diundang juga perlu melakukan taruhan dalam game.',
  'acting.generalboard.分享描述': 'Jadilah agen, dapatkan jutaan setiap bulan! Hadiah undangan {amount}, komisi hingga 90%!',
  'acting.generalboard.tip.复制成功': 'Salin Berhasil',
  'acting.generalboard.二维码标题': 'Mulailah mengubah hidup Anda sekarang',
  'acting.generalboard.二维码描述': 'Rekrut agen, bergabung dengan kami, dan dapatkan rebate super setiap hari',
  'acting.generalboard.保存': 'Simpan',
  'acting.generalboard.如何获得拉新奖励': 'Bagaimana cara mendapatkan hadiah undangan + komisi?',
  'acting.generalboard.如何获得拉新奖励标题1': 'Bagikan ke teman Anda',
  'acting.generalboard.如何获得拉新奖励高亮文字1': 'Bagikan',
  'acting.generalboard.如何获得拉新奖励描述1': 'Bagikan tautan unduhan atau kode QR kepada teman Anda',
  'acting.generalboard.如何获得拉新奖励标题2': 'Teman Unduh dan Daftar',
  'acting.generalboard.如何获得拉新奖励高亮文字2_1': 'Unduh',
  'acting.generalboard.如何获得拉新奖励高亮文字2_2': 'Daftar',
  'acting.generalboard.如何获得拉新奖励描述2': 'Undang teman untuk mendaftar ke aplikasi dan menjadi anggota',
  'acting.generalboard.如何获得拉新奖励标题3': 'Teman Bertaruh',
  'acting.generalboard.如何获得拉新奖励高亮文字3': 'Bertaruh',
  'acting.generalboard.如何获得拉新奖励描述3': 'Berhasil menghubungkan pengguna bawahan + mendapatkan hadiah undangan',
  'acting.generalboard.如何获得拉新奖励标题4': 'Teman Isi Ulang dan Bertaruh',
  'acting.generalboard.如何获得拉新奖励高亮文字4_1': 'Isi ulang',
  'acting.generalboard.如何获得拉新奖励高亮文字4_2': 'Bertaruh',
  'acting.generalboard.如何获得拉新奖励描述4': 'Membuka hadiah undangan + menikmati komisi agen',
  'acting.generalboard.保存成功': 'Disimpan dengan sukses',

  'acting.reward.邀请里程碑': 'Tonggak Undangan',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Hadiah dapat diklaim setelah mencapai jumlah teman yang diundang',
  'acting.reward.邀请': 'Undang',
  'acting.reward.人': 'orang',
  'acting.reward.领取': 'Klaim',
  'acting.reward.直属下级人数': 'Jumlah Bawahan Langsung',
  'acting.reward.邀请奖励': 'Hadiah Undangan',
  'acting.reward.直属下级绑定成功可获得': 'Dapatkan setelah berhasil menghubungkan bawahan langsung',
  'acting.reward.今日邀请': 'Undangan Hari Ini',
  'acting.reward.总邀请': 'Total Undangan',
  'acting.reward.今日奖励': 'Hadiah Hari Ini',
  'acting.reward.邀请总奖励': 'Total Hadiah Undangan',
  'acting.reward.今日邀请人数': 'Jumlah Undangan Hari Ini',
  'acting.reward.邀请总人数': 'Jumlah Total Undangan',
  'acting.reward.用户ID': 'ID Pengguna',
  'acting.reward.昵称': 'Nama Panggilan',
  'acting.reward.奖励': 'Hadiah',
  'acting.reward.展示前50条信息': 'Menampilkan 50 Informasi Pertama',
  'acting.reward.暂无记录': 'Tidak Ada Catatan',
  'acting.reward.领取成功': 'Klaim Berhasil',
  // ----------------------------------------------------------
  'acting.reward.未充值下级': 'Bawahan yang belum top-up',
  'acting.reward.解锁明细': 'Detail pembukaan kunci',
  'acting.reward.拉新奖励领取规则': 'Aturan klaim hadiah undangan pemain baru',
  'acting.reward.充值金额': 'Jumlah top-up',
  'acting.reward.解锁金额': 'Jumlah pembukaan kunci',
  'acting.reward.手机号': 'Nomor telepon',
  'acting.reward.拉新奖励': 'Hadiah undangan pemain baru',
  'acting.reward.充值': 'Top-up',
  'acting.reward.解锁': 'Buka kunci',
  'acting.reward.实际': 'Nyata',
  'acting.reward.锁定奖励': 'Hadiah terkunci',
  'acting.reward.可解锁奖励': 'Hadiah yang bisa dibuka kunci',
  'acting.reward.可领取': 'Dapat diklaim',
  'acting.reward.最高解锁': 'Pembukaan kunci maksimum',
  'acting.reward.解锁公式': 'Rumus pembukaan kunci',
  'acting.reward.解锁公式内容': 'Top-up bawahan * Kecepatan pembukaan kunci',
  'acting.reward.下级充值总人数': 'Jumlah total bawahan yang top-up',
  'acting.reward.当前速率': 'Kecepatan saat ini',
  'acting.reward.邀请下级获取拉新奖励': 'Undang bawahan untuk mendapatkan hadiah undangan {symbol}{amount}',
  'acting.reward.邀请新的代理绑定无需充值可直接解锁': 'Undang pemain baru untuk terhubung tanpa top-up, langsung buka kunci',
  'acting.reward.下级充值可解锁奖励': 'Isi ulang tingkat bawah dapat membuka hadiah',
  'acting.reward.下级充值人数越多解锁速率越高': 'Semakin subordinate memuat ulang, semakin tinggi kecepatan pembukaan',

  'acting.commission.本周佣金信息': 'Informasi Komisi Minggu Ini',
  'acting.commission.下级总充值': 'Total Top Up Bawahan',
  'acting.commission.直属下级佣金': 'Komisi Bawahan Langsung',
  'acting.commission.非直属下级佣金': 'Komisi Bawahan Tidak Langsung',
  'acting.commission.未领取佣金': 'Komisi yang Belum Diterima',
  'acting.commission.领取佣金': 'Klaim Komisi',
  'acting.commission.领取记录': 'Catatan Klaim',
  'acting.commission.佣金总和': 'Total Komisi',
  'acting.commission.领取时间': 'Waktu Klaim',
  'acting.commission.直属佣金': 'Komisi Langsung',
  'acting.commission.非直属佣金': 'Komisi Tidak Langsung',
  'acting.commission.总和': 'Total',
  'acting.commission.展示前50条信息': 'Menampilkan 50 Informasi Pertama',
  'acting.commission.暂无记录': 'Tidak Ada Catatan',
  'acting.commission.领取成功': 'Klaim Berhasil',
  // ----------------------------------------------------------
  'acting.commission.领取': 'Klaim',
  'acting.commission.最多展示前三个月的信息': 'Hanya menampilkan informasi tiga bulan terakhir',
  'acting.commission.佣金规则': 'Aturan komisi',
  'acting.commission.上周下级充值流水': 'Pendapatan top-up bawahan minggu lalu',
  'acting.commission.上周邀请人数': 'Jumlah undangan minggu lalu',
  'acting.commission.佣金比例': 'Persentase komisi',
  'acting.commission.本周结佣比例依据上周下级表现计算': 'Persentase komisi minggu ini dihitung berdasarkan kinerja bawahan minggu lalu',
  'acting.commission.本周详情': 'Detail minggu ini',
  'acting.commission.本周下级充值流水': 'Pendapatan top-up bawahan minggu ini',
  'acting.commission.本周下级充值金流水贡献': 'Kontribusi pendapatan top-up bawahan minggu ini',
  'acting.commission.拉新人数': 'Jumlah pemain baru yang diundang',
  'acting.commission.当前您享受最高佣金比例': 'Saat ini Anda mendapatkan persentase komisi tertinggi {rate}%',
  'acting.commission.完成以上条件下周可享受': 'Selesaikan syarat di atas untuk mendapatkan persentase komisi {rate}% minggu depan',
  'acting.commission.本周佣金比例': 'Persentase komisi minggu ini',
  'acting.commission.上周拉新人数': 'Jumlah pemain baru minggu lalu',
  'acting.commission.返佣比例': 'Persentase pengembalian komisi',
  'acting.commission.本周直属下级佣金': 'Komisi bawahan langsung minggu ini',
  'acting.commission.本周非直属下级佣金': 'Komisi bawahan tidak langsung minggu ini',
  'acting.commission.之前未领取佣金': 'Komisi belum diklaim sebelumnya',
  'acting.commission.本周佣金': 'Komisi minggu ini',
  'acting.commission.周记录': 'Catatan mingguan',
  'acting.commission.时间': 'Waktu',
  'acting.commission.下级充值': 'Top-up bawahan',
  'acting.commission.邀请人数': 'Jumlah undangan',
  'acting.commission.佣金': 'Komisi',
}