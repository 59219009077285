export default {
  'acting.代理': 'ตัวแทน',
  'acting.详情': 'รายละเอียด',
  'acting.奖励': 'รางวัล',
  'acting.佣金': 'ค่าคอมมิชชั่น',
  'acting.规则': 'กฎ',
  
  'acting.generalboard.邀请朋友来获取': 'เชิญเพื่อนเพื่อรับ',
  'acting.generalboard.人': 'คน',
  'acting.generalboard.邀请奖励': 'รางวัลการเชิญ',
  'acting.generalboard.高达': 'สูงสุด',
  'acting.generalboard.佣金奖励': 'รางวัลค่าคอมมิชชั่น',
  'acting.generalboard.描述': 'รับ {amount} สำหรับเพื่อนแต่ละคนที่คุณเชิญ พร้อมค่าคอมมิชชั่นสูงสุดถึง 90% จากการเดิมพันของพวกเขา เพลิดเพลินกับค่าคอมมิชชั่นอย่างต่อเนื่อง ไม่ว่าจะชนะหรือแพ้ เริ่มทำเงินได้เลย!',
  'acting.generalboard.邀请链接': 'ลิงก์เชิญ',
  'acting.generalboard.分享到社交平台': 'แชร์ไปยังแพลตฟอร์มโซเชียล',
  'acting.generalboard.拉新表现': 'รายละเอียดการเชิญ (การลงทะเบียนสำเร็จ)',
  'acting.generalboard.今日拉新': 'การลงทะเบียนวันนี้',
  'acting.generalboard.本周拉新': 'การลงทะเบียนในสัปดาห์นี้',
  'acting.generalboard.直属拉新表现': 'การลงทะเบียนเชิญตรง',
  'acting.generalboard.非直属拉新表现': 'การลงทะเบียนเชิญไม่ตรง',
  'acting.generalboard.下级详情': 'รายละเอียดผู้ใต้บังคับบัญชา (การผูกมัดสำเร็จ)',
  'acting.generalboard.下级总人数': 'จำนวนผู้ใต้บังคับบัญชาทั้งหมด',
  'acting.generalboard.直属下级人数': 'จำนวนผู้ใต้บังคับบัญชาตรง',
  'acting.generalboard.非直属下级人数': 'จำนวนผู้ใต้บังคับบัญชาทางอ้อม',
  'acting.generalboard.总奖励': 'รางวัลทั้งหมด',
  'acting.generalboard.总佣金': 'ค่าคอมมิชชั่นทั้งหมด',
  'acting.generalboard.tip.描述': 'จำนวนข้างต้นหมายถึงจำนวนผู้ที่ลงทะเบียนสำเร็จในเกมผ่านผู้เชิญการผูกมัดผู้ใต้บังคับบัญชาสำเร็จต้องให้ผู้ที่ได้รับการเชิญทำการเดิมพันในเกมด้วย',
  'acting.generalboard.分享描述': 'เป็นตัวแทน รับรายได้หลักล้านต่อเดือน! รางวัลเชิญ {amount} ค่าคอมมิชชั่นสูงสุดถึง 90%!',
  'acting.generalboard.tip.复制成功': 'คัดลอกสำเร็จ',
  'acting.generalboard.二维码标题': 'เริ่มเปลี่ยนชีวิตของคุณตั้งแต่ตอนนี้',
  'acting.generalboard.二维码描述': 'รับสมัครตัวแทน, เข้าร่วมกับเรา, รับเงินคืนสุดพิเศษทุกวัน',
  'acting.generalboard.保存': 'บันทึก',
  'acting.generalboard.如何获得拉新奖励': 'จะได้รับรางวัลการแนะนำ + ค่าคอมมิชชันอย่างไร?',
  'acting.generalboard.如何获得拉新奖励标题1': 'แชร์กับเพื่อนของคุณ',
  'acting.generalboard.如何获得拉新奖励高亮文字1': 'แชร์',
  'acting.generalboard.如何获得拉新奖励描述1': 'แชร์ลิงก์ดาวน์โหลดหรือ QR code ให้เพื่อนของคุณ',
  'acting.generalboard.如何获得拉新奖励标题2': 'เพื่อนดาวน์โหลดและสมัครสมาชิก',
  'acting.generalboard.如何获得拉新奖励高亮文字2_1': 'ดาวน์โหลด',
  'acting.generalboard.如何获得拉新奖励高亮文字2_2': 'สมัครสมาชิก',
  'acting.generalboard.如何获得拉新奖励描述2': 'เชิญเพื่อนสมัครแอปและเป็นสมาชิก',
  'acting.generalboard.如何获得拉新奖励标题3': 'เพื่อนวางเดิมพัน',
  'acting.generalboard.如何获得拉新奖励高亮文字3': 'วางเดิมพัน',
  'acting.generalboard.如何获得拉新奖励描述3': 'ผูกบัญชีผู้ใช้งานระดับล่างสำเร็จ + รับโบนัสการแนะนำ',
  'acting.generalboard.如何获得拉新奖励标题4': 'เพื่อนเติมเงินและวางเดิมพัน',
  'acting.generalboard.如何获得拉新奖励高亮文字4_1': 'เติมเงิน',
  'acting.generalboard.如何获得拉新奖励高亮文字4_2': 'วางเดิมพัน',
  'acting.generalboard.如何获得拉新奖励描述4': 'ปลดล็อกรางวัลการแนะนำ + เพลิดเพลินกับค่าคอมมิชชันตัวแทน',
  'acting.generalboard.保存成功': 'บันทึกความสำเร็จ',

  'acting.reward.邀请里程碑': 'ก้าวสำคัญของการเชิญ',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'เชิญเพื่อนครบตามกำหนดเพื่อรับรางวัลที่สอดคล้องกัน',
  'acting.reward.邀请': 'เชิญ',
  'acting.reward.人': 'คน',
  'acting.reward.领取': 'รับ',
  'acting.reward.直属下级人数': 'จำนวนผู้ใต้บังคับบัญชาตรง',
  'acting.reward.邀请奖励': 'รางวัลการเชิญ',
  'acting.reward.直属下级绑定成功可获得': 'รับรางวัลเมื่อผู้ใต้บังคับบัญชาตรงผูกสำเร็จ',
  'acting.reward.今日邀请': 'เชิญวันนี้',
  'acting.reward.总邀请': 'เชิญทั้งหมด',
  'acting.reward.今日奖励': 'รางวัลวันนี้',
  'acting.reward.邀请总奖励': 'รางวัลการเชิญทั้งหมด',
  'acting.reward.今日邀请人数': 'จำนวนเชิญวันนี้',
  'acting.reward.邀请总人数': 'จำนวนเชิญทั้งหมด',
  'acting.reward.用户ID': 'รหัสผู้ใช้',
  'acting.reward.昵称': 'ชื่อเล่น',
  'acting.reward.奖励': 'รางวัล',
  'acting.reward.展示前50条信息': 'แสดงข้อมูล 50 รายการแรก',
  'acting.reward.暂无记录': 'ไม่มีบันทึก',
  'acting.reward.领取成功': 'รับสำเร็จ',
  // ----------------------------------------------------------
  'acting.reward.未充值下级': 'ระดับล่างที่ยังไม่ได้เติมเงิน',
  'acting.reward.解锁明细': 'รายละเอียดการปลดล็อก',
  'acting.reward.拉新奖励领取规则': 'กฎการรับรางวัลเชิญเพื่อนใหม่',
  'acting.reward.充值金额': 'จำนวนเงินเติม',
  'acting.reward.解锁金额': 'จำนวนเงินปลดล็อก',
  'acting.reward.手机号': 'เบอร์โทรศัพท์',
  'acting.reward.拉新奖励': 'รางวัลเชิญเพื่อนใหม่',
  'acting.reward.充值': 'เติมเงิน',
  'acting.reward.解锁': 'ปลดล็อก',
  'acting.reward.实际': 'จริง',
  'acting.reward.锁定奖励': 'รางวัลที่ล็อกไว้',
  'acting.reward.可解锁奖励': 'รางวัลที่ปลดล็อกได้',
  'acting.reward.可领取': 'รับได้',
  'acting.reward.最高解锁': 'ปลดล็อกสูงสุด',
  'acting.reward.解锁公式': 'สูตรปลดล็อก',
  'acting.reward.解锁公式内容': 'ยอดเติมเงินระดับล่าง * อัตราปลดล็อก',
  'acting.reward.下级充值总人数': 'จำนวนระดับล่างที่เติมเงินทั้งหมด',
  'acting.reward.当前速率': 'อัตราปัจจุบัน',
  'acting.reward.邀请下级获取拉新奖励': 'เชิญระดับล่างเพื่อรับรางวัลเชิญเพื่อนใหม่ {symbol}{amount}',
  'acting.reward.邀请新的代理绑定无需充值可直接解锁': 'เชิญผู้เล่นใหม่ผูกบัญชี ปลดล็อกได้โดยไม่ต้องเติมเงิน',
  'acting.reward.下级充值可解锁奖励': 'การเติมเงินระดับล่างสามารถปลดล็อกของรางวัลได้',
  'acting.reward.下级充值人数越多解锁速率越高': 'ยิ่งเติมเงินระดับล่างมากเท่าไร อัตราการปลดล็อกก็ยิ่งสูงขึ้น',

  'acting.commission.本周佣金信息': 'ข้อมูลค่าคอมมิชชั่นประจำสัปดาห์นี้',
  'acting.commission.下级总充值': 'ยอดเติมเงินทั้งหมด',
  'acting.commission.直属下级佣金': 'ค่าคอมมิชชั่นผู้ใต้บังคับบัญชาตรง',
  'acting.commission.非直属下级佣金': 'ค่าคอมมิชชั่นผู้ใต้บังคับบัญชาทางอ้อม',
  'acting.commission.未领取佣金': 'ค่าคอมมิชชั่นที่ยังไม่ได้รับ',
  'acting.commission.领取佣金': 'รับค่าคอมมิชชั่น',
  'acting.commission.领取记录': 'ประวัติการรับ',
  'acting.commission.佣金总和': 'ค่าคอมมิชชั่นทั้งหมด',
  'acting.commission.领取时间': 'เวลาการรับ',
  'acting.commission.直属佣金': 'ค่าคอมมิชชั่นตรง',
  'acting.commission.非直属佣金': 'ค่าคอมมิชชั่นทางอ้อม',
  'acting.commission.总和': 'ยอดรวม',
  'acting.commission.展示前50条信息': 'แสดงข้อมูล 50 รายการแรก',
  'acting.commission.暂无记录': 'ไม่มีบันทึก',
  'acting.commission.领取成功': 'รับสำเร็จ',
  // ----------------------------------------------------------
  'acting.commission.领取': 'รับ',
  'acting.commission.最多展示前三个月的信息': 'แสดงข้อมูลย้อนหลังไม่เกิน 3 เดือน',
  'acting.commission.佣金规则': 'กฎค่าคอมมิชชั่น',
  'acting.commission.上周下级充值流水': 'รายรับเมื่อสัปดาห์ที่แล้ว',
  'acting.commission.上周邀请人数': 'คำเชิญเมื่อสัปดาห์ที่แล้ว',
  'acting.commission.佣金比例': 'อัตราค่าคอมมิชชั่น',
  'acting.commission.本周结佣比例依据上周下级表现计算': 'อัตราค่าคอมมิชชั่นของสัปดาห์นี้คำนวณจากผลงานระดับล่างในสัปดาห์ที่แล้ว',
  'acting.commission.本周详情': 'รายละเอียดสัปดาห์นี้',
  'acting.commission.本周下级充值流水': 'ยอดเติมเงินในสัปดาห์นี้',
  'acting.commission.本周下级充值金流水贡献': 'การมีส่วนร่วมยอดเติมเงินระดับล่างสัปดาห์นี้',
  'acting.commission.拉新人数': 'จำนวนคนเชิญใหม่',
  'acting.commission.当前您享受最高佣金比例': 'คุณได้รับอัตราค่าคอมมิชชั่นสูงสุด {rate}%',
  'acting.commission.完成以上条件下周可享受': 'หากทำตามเงื่อนไขข้างต้นสำเร็จ สัปดาห์หน้าจะได้รับอัตราค่าคอมมิชชั่น {rate}%',
  'acting.commission.本周佣金比例': 'อัตราค่าคอมมิชชั่นสัปดาห์นี้',
  'acting.commission.上周拉新人数': 'จำนวนคนเชิญใหม่ในสัปดาห์ที่แล้ว',
  'acting.commission.返佣比例': 'อัตราคืนค่าคอมมิชชั่น',
  'acting.commission.本周直属下级佣金': 'ค่าคอมมิชชั่นระดับล่างที่เกี่ยวข้องโดยตรงสัปดาห์นี้',
  'acting.commission.本周非直属下级佣金': 'ค่าคอมมิชชั่นระดับล่างที่ไม่เกี่ยวข้องโดยตรงสัปดาห์นี้',
  'acting.commission.之前未领取佣金': 'ค่าคอมมิชชันที่ยังไม่ได้รับ',
  'acting.commission.本周佣金': 'ค่าคอมมิชชั่นสัปดาห์นี้',
  'acting.commission.周记录': 'บันทึกประจำสัปดาห์',
  'acting.commission.时间': 'เวลา',
  'acting.commission.下级充值': 'เติมเงินระดับล่าง',
  'acting.commission.邀请人数': 'จำนวนคนเชิญ',
  'acting.commission.佣金': 'ค่าคอมมิชชั่น',
}