export default {
  'acting.代理': 'Đại lý',

  'acting.详情': 'Chi tiết',
  'acting.奖励': 'Phần thưởng',
  'acting.佣金': 'Hoa hồng',
  'acting.规则': 'Quy tắc',

  'acting.generalboard.邀请朋友来获取': 'Mời bạn bè để nhận',
  'acting.generalboard.人': 'Người',
  'acting.generalboard.邀请奖励': 'Thưởng mời',
  'acting.generalboard.高达': 'Lên tới',
  'acting.generalboard.佣金奖励': 'Thưởng hoa hồng',
  'acting.generalboard.描述': 'Mời bạn bè {amount} /người, còn nhận thêm hoa hồng lên đến 90% cho tiền cược của các bạn bè. Tận hưởng hoa hồng ổn định, dù họ thắng hay thua. Bắt đầu kiếm tiền ngay!',
  'acting.generalboard.邀请链接': 'Link mời',
  'acting.generalboard.分享到社交平台': 'Chia sẻ lên nền tảng xã hội',
  'acting.generalboard.拉新表现': 'Chi tiết Mời (Đăng ký thành công)',
  'acting.generalboard.今日拉新': 'Đăng ký hôm nay',
  'acting.generalboard.本周拉新': 'Đăng ký tuần này',
  'acting.generalboard.直属拉新表现': 'F1 Đăng ký',
  'acting.generalboard.非直属拉新表现': 'Ngoài F1 Đăng ký',
  'acting.generalboard.下级详情': 'Chi tiết cấp dưới (Bind thành công)',
  'acting.generalboard.下级总人数': 'Tổng số cấp dưới',
  'acting.generalboard.直属下级人数': 'Số người F1',
  'acting.generalboard.非直属下级人数': 'Số người ngoài F1',
  'acting.generalboard.总奖励': 'Tổng thưởng',
  'acting.generalboard.总佣金': 'Tổng hoa hồng',
  'acting.generalboard.tip.描述': 'Số lượng trên đại diện cho số người đã đăng ký thành công game thông qua người mời.Để bind cấp dưới thành công, người được mời còn cần đặt cược trong game.',
  'acting.generalboard.分享描述': 'Trở thành đại lý và kiếm hàng triệu mỗi tháng! Phần thưởng mời {amount}, hoa hồng lên tới 90%!',
  'acting.generalboard.tip.复制成功': 'Copy thành công',
  'acting.generalboard.二维码标题': 'Bắt đầu thay đổi cuộc sống ngay từ bây giờ',
  'acting.generalboard.二维码描述': 'Tuyển dụng đại lý, gia nhập chúng tôi, và nhận hoàn tiền siêu hấp dẫn mỗi ngày',
  'acting.generalboard.保存': 'Lưu',
  'acting.generalboard.如何获得拉新奖励': 'Cách nhận thưởng mời + hoa hồng?',
  'acting.generalboard.如何获得拉新奖励标题1': 'Chia sẻ với bạn bè của bạn',
  'acting.generalboard.如何获得拉新奖励高亮文字1': 'Chia sẻ',
  'acting.generalboard.如何获得拉新奖励描述1': 'Chia sẻ link tải xuống hoặc mã QR với bạn bè của bạn',
  'acting.generalboard.如何获得拉新奖励标题2': 'Bạn bè Tải xuống và Đăng ký',
  'acting.generalboard.如何获得拉新奖励高亮文字2_1': 'Tải xuống',
  'acting.generalboard.如何获得拉新奖励高亮文字2_2': 'Đăng ký',
  'acting.generalboard.如何获得拉新奖励描述2': 'Mời bạn bè đăng ký ứng dụng và trở thành thành viên',
  'acting.generalboard.如何获得拉新奖励标题3': 'Bạn bè Đặt cược',
  'acting.generalboard.如何获得拉新奖励高亮文字3': 'Đặt cược',
  'acting.generalboard.如何获得拉新奖励描述3': 'Liên kết cấp dưới thành công + nhận thưởng mời',
  'acting.generalboard.如何获得拉新奖励标题4': 'Bạn bè Nạp tiền và Đặt cược',
  'acting.generalboard.如何获得拉新奖励高亮文字4_1': 'Nạp tiền',
  'acting.generalboard.如何获得拉新奖励高亮文字4_2': 'Đặt cược',
  'acting.generalboard.如何获得拉新奖励描述4': 'Mở khóa thưởng mời + tận hưởng hoa hồng đại lý',
  'acting.generalboard.保存成功': 'Lưu thành công',

  'acting.reward.邀请里程碑': 'Mốc thành tích mời',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Sau khi mời đủ số lượng bạn bè yêu cầu, bạn có thể nhận được phần thưởng tương ứng.',
  'acting.reward.邀请': 'Mời',
  'acting.reward.人': 'Người',
  'acting.reward.领取': 'Nhận',
  'acting.reward.直属下级人数': 'Số người F1',
  'acting.reward.邀请奖励': 'Thưởng mời',
  'acting.reward.直属下级绑定成功可获得': 'Bind thành công người F1 để nhận',
  'acting.reward.今日邀请': 'Mời hôm nay',
  'acting.reward.总邀请': 'Tổng số mời',
  'acting.reward.今日奖励': 'Thưởng hôm nay',
  'acting.reward.邀请总奖励': 'Tổng thưởng mời',
  'acting.reward.今日邀请人数': 'Tổng số mời hôm nay',
  'acting.reward.邀请总人数': 'Tổng số mời',
  'acting.reward.用户ID': 'ID',
  'acting.reward.昵称': 'Biệt danh',
  'acting.reward.奖励': 'Thưởng',
  'acting.reward.展示前50条信息': 'Hiển thị 50 tin nhắn đầu tiên',
  'acting.reward.暂无记录': 'Chưa có lịch sử nào',
  'acting.reward.领取成功': 'Nhận thành công',
  // ----------------------------------------------------------
  'acting.reward.未充值下级': 'Chưa nạp cấp dưới',
  'acting.reward.解锁明细': 'Chi tiết mở khóa',
  'acting.reward.拉新奖励领取规则': 'Quy tắc nhận thưởng mời',
  'acting.reward.充值金额': 'Số tiền nạp',
  'acting.reward.解锁金额': 'Số tiền mở khóa',
  'acting.reward.手机号': 'Số điện thoại',
  'acting.reward.拉新奖励': 'Thưởng mời',
  'acting.reward.充值': 'Nạp tiền',
  'acting.reward.解锁': 'Mở khóa',
  'acting.reward.实际': 'Thực tế',
  'acting.reward.锁定奖励': 'Thưởng bị khóa',
  'acting.reward.可解锁奖励': 'Thưởng được mở khóa',
  'acting.reward.可领取': 'Được nhận',
  'acting.reward.最高解锁': 'Mở khóa cao nhất',
  'acting.reward.解锁公式': 'Cách mở khóa',
  'acting.reward.解锁公式内容': 'Số tiền nạp của một cấp dưới * Tỷ lệ mở khóa',
  'acting.reward.下级充值总人数': 'Tổng số cấp dưới nạp tiền',
  'acting.reward.当前速率': 'Tỷ lệ hiện tại',
  'acting.reward.邀请下级获取拉新奖励': 'Mời cấp dưới để nhận thưởng mời {symbol}{amount}',
  'acting.reward.邀请新的代理绑定无需充值可直接解锁': 'Mời người chơi mới liên kết không cần nạp tiền để mở khóa trực tiếp',
  'acting.reward.下级充值可解锁奖励': 'Cấp dưới  nạp để mở khóa thưởng',
  'acting.reward.下级充值人数越多解锁速率越高': 'Số người nạp càng nhiều, tốc độ mở khóa càng cao.',

  'acting.commission.本周佣金信息': 'Thông tin hoa hồng tuần này',
  'acting.commission.下级总充值': 'Tổng nạp cấp dưới',
  'acting.commission.直属下级佣金': 'Hoa hồng F1',
  'acting.commission.非直属下级佣金': 'Hoa hồng ngoài F1',
  'acting.commission.未领取佣金': 'Chưa nhận hoa hồng',
  'acting.commission.领取佣金': 'Nhận hoa hồng',
  'acting.commission.领取记录': 'Lịch sử nhận',
  'acting.commission.佣金总和': 'Tổng hoa hồng',
  'acting.commission.领取时间': 'Thời gian nhận',
  'acting.commission.直属佣金': 'Hoa hồng F1',
  'acting.commission.非直属佣金': 'Hoa hồng ngoài F1',
  'acting.commission.总和': 'Tổng',
  'acting.commission.展示前50条信息': 'Hiển thị 50 tin nhắn đầu tiên',
  'acting.commission.暂无记录': 'Chưa có lịch sử nào',
  'acting.commission.领取成功': 'Nhận thành công',
  // ----------------------------------------------------------
  'acting.commission.领取': 'Nhận',
  'acting.commission.最多展示前三个月的信息': 'Chỉ hiển thị thông tin trong 3 tháng gần nhất',
  'acting.commission.佣金规则': 'Quy tắc hoa hồng',
  'acting.commission.上周下级充值流水': 'Lượng cược tuần trước',
  'acting.commission.上周邀请人数': 'Số mời tuần trước',
  'acting.commission.佣金比例': 'Tỷ lệ hoa hồng',
  'acting.commission.本周结佣比例依据上周下级表现计算': 'Tỷ lệ kết toán hoa hồng tuần này dựa trên hiệu suất cấp dưới tuần trước',
  'acting.commission.本周详情': 'Chi tiết tuần này',
  'acting.commission.本周下级充值流水': 'Doanh thu nạp tiền cấp dưới tuần này',
  'acting.commission.本周下级充值金流水贡献': 'Đóng góp cá cược nạp tiền cấp dưới tuần này',
  'acting.commission.拉新人数': 'Số mời',
  'acting.commission.当前您享受最高佣金比例': 'Hiện tại bạn được hưởng tỷ lệ hoa hồng cao nhất {rate}%',
  'acting.commission.完成以上条件下周可享受': 'Hoàn thành các điều kiện trên, tuần sau có thể hưởng tỷ lệ hoa hồng {rate}%',
  'acting.commission.本周佣金比例': 'Tỷ lệ hoa hồng tuần này',
  'acting.commission.上周拉新人数': 'Số mời tuần trước',
  'acting.commission.返佣比例': 'Tỷ lệ hoa hồng',
  'acting.commission.本周直属下级佣金': 'Hoa hồng F1 tuần này',
  'acting.commission.本周非直属下级佣金': 'Hoa hồng ngoài F1 tuần này',
  'acting.commission.之前未领取佣金': 'Hoa hồng chưa nhận',
  'acting.commission.本周佣金': 'Hoa hồng tuần này',
  'acting.commission.周记录': 'Lịch sử tuần',
  'acting.commission.时间': 'Thời gian',
  'acting.commission.下级充值': 'Nạp tiền cấp dưới',
  'acting.commission.邀请人数': 'Số mời',
  'acting.commission.佣金': 'Hoa hồng',
}